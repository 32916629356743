import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/CAT/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Address = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> A qui ens dirigim </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> Beneficiaris directes </h3>
                        <ul>
                            <li> Entrenadors que
treballen en l’àmbit de l’esport unificat/adaptat en general i en el de la dansa
adaptada/unificada en particular. </li> 
                            <li>  Entitats no lucratives/institucions públiques/centres de formació que
treballen en els àmbits de la inclusió social i els esports/dansa i estan interessats en promoure
el desenvolupament professional dels seus entrenadors en el camp de la dansa
adaptada/unificada.</li>
                            <li> persones amb o sense discapacitat. </li>
                        
                        </ul>

                        <h3> Beneficiaris indirectes </h3>
        <ul>
            <li>Famílies senceres o membres familiars que cuiden a
persones amb discapacitat, representants legals, especialistes i membres de la comunitat.</li>
            <li> Representants
d’institucions de política pública als països participants.</li>
            <li> Representants d’institucions públiques
i privades. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Address